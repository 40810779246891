import type { ServiceWorkerGlobalScope, Console } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';
import type { default as TypedDexie, DexieConstructor, liveQuery, mergeRanges, rangesOverlap } from 'o365.pwa.declaration.sw.dexie.d.ts';
import type { default as Workbox } from 'o365.pwa.declaration.sw.workbox.d.ts';
import type { IO365 } from 'o365.pwa.declaration.sw.O365.d.ts';
import type { sort, createNewSortInstance, defaultComparer, inPlaceSort } from 'o365.pwa.declaration.sw.FastSort.d.ts';

export interface DexieInstance {
    Dexie: TypedDexie & DexieConstructor;
    RangeSet: any;
    default: TypedDexie & DexieConstructor;
    liveQuery: typeof liveQuery;
    mergeRanges: typeof mergeRanges;
    rangesOverlap: typeof rangesOverlap;
}

export interface IO365ServiceWorkerGlobalScope extends ServiceWorkerGlobalScope {
    o365: IO365;
    workbox: Workbox;
    Dexie: {
        get latestVersion(): DexieInstance
        [key: string]: DexieInstance
    };
    mime: any;
    FastSort: {
        createNewSortInstance: typeof createNewSortInstance;
        defaultComparer: typeof defaultComparer;
        sort: typeof sort;
        inPlaceSort: typeof inPlaceSort;
    };
    __WB_DISABLE_DEV_LOGS: boolean;
    console: Console;
}
